import { ViewportScroller } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InfoService } from 'src/app/services/info.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input() active: string = ""

  constructor(
    public router: Router,
    private scroller: ViewportScroller,
    public infoService: InfoService

  ) {
    scroller.setOffset([0,70])
  }

  ngOnInit(): void {
  }

  goDown(value: string) {
    this.scroller.scrollToAnchor(value);
  }

}
