<app-header [active]="currentID"></app-header>
<!-- <app-menu class="d-block d-md-none"></app-menu>
 -->
<app-banner-home id="home" class="d-none d-md-block"></app-banner-home>
<app-banner-home-mobile id="home" class="d-flex d-md-none"></app-banner-home-mobile>
<app-case-studies *ngIf="infoService.hasPosts" id="case-studies"></app-case-studies>

<app-about-us id="about-us"></app-about-us>
<!-- <app-subscription id="subscription"></app-subscription> -->
<app-region id="region"></app-region>
<app-team id="team"></app-team>
<app-footer id="footer"></app-footer>
<app-scroll-top class="scroll-top" *ngIf="scrollY > height"></app-scroll-top>
