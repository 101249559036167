<app-title-line sectionName="case studies"></app-title-line>
<div class="section-odd d-flex align-items-center">
  <div class="container d-flex flex-column align-items-center justify-content-center container-cards">
    <div class="row my-1 my-md-0" *ngIf="listOfPosts">
      <div class="col-12 col-md-4" *ngFor="let post of listOfPosts.posts; let k = index" [@inOutAnimation]>
        <div class="card m-1 m-mb-0 mb-4 mb-md-4" >
          <h5 class="card-title text-uppercase p-3" >{{post.caption | slice:0:30}}...</h5>
          <div class="card-body d-flex flex-column align-items-center justify-content-start justify-content-md-between">
            <img *ngIf="post.media_type === 'IMAGE'" class="img-fluid" src={{post.media_url}} alt="image">
            <div *ngIf="post.media_type === 'CAROUSEL_ALBUM'">
              <div id="carouselExample{{k}}" class="carousel slide">
                <div class="carousel-inner">
                  <div class="carousel-item" *ngFor="let image of post?.carouselImages; let i = index"
                    [class.active]="i == 0">
                    <img class="d-block w-100" src={{image}} alt={{i+1}}>
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" [attr.data-bs-target]="getCarouselTargetId(k)"
                  data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" [attr.data-bs-target]="getCarouselTargetId(k)"
                  data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <p class="card-text p-1">{{post.caption | slice:0:100}}<a href={{post.permalink}} target="_blank"
                rel="noopener noreferrer" class="link-ver-mais">... Ver mais</a></p>
            <a class="card-button text-uppercase mb-3" href={{post.permalink}} target="_blank">Consultar</a>
          </div>
        </div>
      </div>
    </div> 
    <div *ngIf="loadingService.loading$ | async else showMore" class="container d-flex justify-content-center my-5">
      <div class="spinner"></div>
    </div>
    <ng-template #showMore>
      <div class="row mb-3" *ngIf="!listOfPosts.last">
      <button  class="btn show-more"
        (click)="nextPage(listOfPosts.paginas)">Mostrar mais <i class="arrow down"></i></button>
    </div>
    </ng-template>
  </div>
</div>
